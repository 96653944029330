import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { formatDuration } from "@/app/util.mjs";
import MatchAccolades from "@/game-deadlock/components/MatchAccolades.jsx";
import MatchTileHits from "@/game-deadlock/components/MatchTileHits.jsx";
import MatchTileScoreboard from "@/game-deadlock/components/MatchTileScoreboard.jsx";
import MatchTileStatistics from "@/game-deadlock/components/MatchTileStatistics.jsx";
import type { MatchlistMeta } from "@/game-deadlock/models/matchlist.mjs";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import getPlayer from "@/game-deadlock/utils/get-player.mjs";
import DotIcon from "@/inline-assets/dot.svg";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import { MainColumnsContainer } from "@/shared/Match.style.jsx";
import ShareButton from "@/shared/ShareButton.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Match() {
  const {
    parameters: [steamId, matchId],
  } = useRoute();
  const {
    deadlock: { profiles, matches, heroes: $heroes },
  } = useSnapshot(readState);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const heroes = useEvalState($heroes);
  const profile = useEvalState(profiles[steamId]) as MatchlistMeta["player"];
  const $match = matches[matchId];
  const match = useEvalState($match);
  const player = useMemo(() => getPlayer(steamId, match), [steamId, match]);
  return (
    <SharedMatchLayout
      match={$match}
      title={[heroes?.[player?.hero_id]?.heroName, profile?.account_id]
        .filter(Boolean)
        .join(" ")}
      image={
        Assets.getHeroPortrait(player?.hero_id) || Assets.getDeadlockLogo()
      }
      imageLink={`/deadlock/profile/${steamId}`}
      borderColor="var(--shade3)"
      underTitle={
        <div className="flex align-center gap-2 color-shade2">
          {[
            match && player && match.winning_team === player.team
              ? t("common:victory", "Victory")
              : t("common:defeat", "Defeat"),
            player?.level >= 0 &&
              t("tft:levelValue", "Level {{level}}", {
                level: player.level.toLocaleString(language),
              }),
            match?.duration_s > 0 &&
              t("common:minutes", "{{minutes}} mins", {
                minutes: formatDuration(
                  sanitizeNumber(match.duration_s * 1000),
                  "m:ss",
                ),
              }),
          ]
            .filter(Boolean)
            .map((i) => (
              <React.Fragment key={i}>
                <span className="type-callout color-shade2">{i}</span>
                <DotIcon style={{ color: "var(--shade3)" }} />
              </React.Fragment>
            ))}
          {!!match?.start_time && (
            <div className="type-callout color-shade2">
              <TimeAgo
                date={new Date(match.start_time * 1000)}
                className={undefined}
              />
            </div>
          )}
          <DotIcon style={{ color: "var(--shade3)" }} />
          <ShareButton />
        </div>
      }
    >
      <MainColumnsContainer>
        <div
          className="span-1 flex direction-column"
          style={{ display: "flex" }} // This overrides MainColumnsContainer's display grid
        >
          <MatchAccolades />
        </div>
        <div className="span-2">
          <MatchTileHits matchId={matchId} steamId={steamId} />
          <MatchTileStatistics matchId={matchId} steamId={steamId} />
          <MatchTileScoreboard matchId={matchId} steamId={steamId} />
        </div>
      </MainColumnsContainer>
    </SharedMatchLayout>
  );
}

export function meta([profileId]) {
  return {
    title: [
      "deadlock:meta.match.title",
      "{{name}}'s Match Stats – Blitz Deadlock",
      { name: profileId },
    ],
    description: [
      "deadlock:meta.match.description",
      "Match Stats for {{name}}",
      { name: profileId },
    ],
  };
}
